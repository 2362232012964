<template>
	<banner-style1 ref="banner" :position="6"></banner-style1>
	<div class="expert-details ul-none">
		<nav class="c w">
			<ul>
				<li v-for="item in list.nav" :key="item.id">
					<span :class="{ on: item.value === listCurrent }"
						@click="navClick(item.value)">{{ item.name }}</span>
				</li>
			</ul>
		</nav>
		<div class="con c w">
			<div class="le-tool" :style="{top:scrollTopFiexd+'px'}">
				<ul>
					<li v-for="item in list.list" :key="item.name" @click="letterClick(item)">
						<a :class="{ lon: letterCurrent === item.initialChar}">{{ item.initialChar }}</a>
					</li>
				</ul>
			</div>
			<div class="con-warp">
				<div :id="item.initialChar" class="letter-content" v-for="item in list.list" :key="item.initialChar">
					<ul>
						<li v-for="p in item.child" :key="p.id" @click="toPage(p.id)">
							<span class="name">{{ p.name }}</span>
							<span class="school" v-if="p.unionName">({{ p.unionName }})</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		onUnmounted,
		reactive,
		ref
	} from "vue";
	import {
		useRouter
	} from 'vue-router'
	import {
		getExpertList
	} from '@/api/competition'

	const route = useRouter()

	function toPage(id) {
		route.push({
			path: '/expert/detail',
			query: {
				id: id
			}
		})
	}

	const listCurrent = ref('');
	const list = reactive({
		nav: [{
			name: '全部专家',
			value: '',
		}, {
			name: '高校专家',
			value: 1,
		}, {
			name: '企业专家',
			value: 2,
		}, {
			name: '海外专家',
			value: 3,
		}],
		list: []
	});

	function navClick(i) {
		listCurrent.value = i;
		getList()
	}

	const letterCurrent = ref('')

	let banner = ref('')
	onMounted(() => {
		console.log(banner.value, 'banner')
	})


	function letterClick(i) {
		letterCurrent.value = i.initialChar
		// let initNum = 740
		let initNum = 110
		let el = window.document.getElementById(i.initialChar)
		initNum += el.offsetTop
		console.log(el.offsetTop, 'el');
		if (el) {
			window.scrollTo({
				top: initNum,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	function getList() {
		getExpertList({
			type: listCurrent.value
		}).then(res => {
			letterSort(res.data)
		})
	}

	function letterSort(arr) {
		let data = []
		arr.forEach(v => {
			let index = data.findIndex((p, pi) => {
				return p.initialChar === v.initialChar
			})
			if (index != -1) {
				data[index].child.push(v)
			} else {
				data.push({
					initialChar: v.initialChar,
					child: [
						v
					]
				})
			}
		})

		data.sort((a, b) => {
			let a1 = a.initialChar.toUpperCase();
			let a2 = b.initialChar.toUpperCase();
			if (a1 < a2) {
				return -1;
			}
			if (a1 > a2) {
				return 1;
			}
			return 0;
		})
		list.list = data
	}
	let scrollTopFiexd = ref(0)
	onMounted(() => {
		window.addEventListener('scroll', handleScroll) // 监听页面滚动
	})

	function handleScroll(e) {
		// let init = 700
		let init = 0
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		if (scrollTop > init) {
			scrollTopFiexd.value = scrollTop - init
		} else {
			scrollTopFiexd.value = 0
		}
	}
	onUnmounted(() => {
		window.removeEventListener('scroll', handleScroll)
	})
	getList()
</script>

<style lang="scss" scoped>
	.expert-details {
		padding-top: 40px;
		padding-bottom: 200px;

		nav {
			font-weight: 700;
			background-color: #e5e5e5;
			padding: 20px 40px;
			box-sizing: border-box;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 1;
					text-align: center;

					span {
						display: inline-block;
						padding: 6px 20px;
						cursor: pointer;
					}
				}

				.on {
					background-color: #59ad96;
					color: #fff;
					border-radius: 4px;
				}
			}
		}
	}

	.con {
		position: relative;

		.le-tool {
			position: absolute;
			border: 1px solid #e5e5e5;
			left: 0;
			top: 0;
			font-weight: 700;
			width: 30px;
			z-index: 99;
			transition: all .2s;

			li {
				text-align: center;
				padding: 10px 0;
				cursor: pointer;

				a {
					color: #000;
					text-decoration: none;
				}

				.lon {
					color: #e0612f;
				}
			}
		}

		.con-warp {
			margin-left: 50px;

			.letter-content {
				border-bottom: 1px dashed #8c8c8c;
				padding: 20px 0;

				ul {
					display: flex;
					flex-wrap: wrap;

					li {
						padding: 10px 20px;
						cursor: pointer;

						&:hover {
							background-color: $themeColor;
							color: #fff;
							border-radius: 10px;
						}

						.name {
							font-weight: 700;
							font-size: 18px;
						}

						.school {
							color: #8c8c8c;
						}
					}
				}
			}

		}

		.content-html {
			padding: 20px 0;
		}
	}
</style>
