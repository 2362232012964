import request from '@/utils/request'

// 获取导航信息
export function getCompetitionIntroduce(params) {
	return request({
		url: '/competition/getCompetitionIntroduce',
		method: 'get',
		params
	})
}

// 获取专家列表
export function getExpertList(params) {
	return request({
		url: '/competition/getExpertList',
		method: 'get',
		params
	})
}

// 获取专家详情
export function getExpertInfo(params) {
	return request({
		url: '/competition/getExpertInfo',
		method: 'get',
		params
	})
}

// 获取专家详情
export function getCompetitionInfo(params) {
	return request({
		url: '/competition/getCompetitionInfo',
		method: 'get',
		params
	})
}

// 获取赛区详情
export function getAreaInfo(params) {
	return request({
		url: '/competition/getAreaInfo',
		method: 'get',
		params
	})
}

// 获取内容详情
export function getContentInfo(form) {
	return request({
		url: '/ZqContent/getContentInfo',
		method: 'get',
		params: form
	})
}

// 分页查询内容信息
export function getList(form) {
	return request({
		url: '/ZqContent/getList',
		method: 'get',
		params: form
	})
}

// 获取报名页所需基础数据
export function getWorksBaseData(form) {
	return request({
		url: '/works/getWorksBaseData',
		method: 'get',
		params: form
	})
}

// 再次提交作品
export function reSubmitWorks(form) {
	return request({
		url: '/works/reSubmitWorks',
		method: 'post',
		data: form
	})
}

// 保存报名信息
export function saveWorksInfo(form) {
	return request({
		url: '/works/saveWorksInfo',
		method: 'post',
		data: form
	})
}

// 获取参赛作品列表
export function getWorksList(form) {
	return request({
		url: '/works/getWorksList',
		method: 'get',
		params: form
	})
}


